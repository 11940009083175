import { get, post, destory, put } from './httpservices'
import { store } from '../store/store'
import { router } from '../router/index'
import { userprofile } from './authservices'

export async function getcompany_id() {
	if (store.state.auth.user_data.company_id === '') {
		await userprofile()
		return store.state.auth.user_data.company_id
	} else {
		return store.state.auth.user_data.company_id
	}
}

export async function bid(bid_data) {
	let company_id = await getcompany_id()
	if (company_id) {
		let resp
		try {
			resp = await post('distributor/bid/' + company_id, bid_data)
			if (resp.data.hold_status === 227 && resp.status === 201) {
				store.commit('showAlert', {
					show: true,
					message: resp.data.message,
					type: 'error',
				})

				return {
					data: null,
					message: resp.data.message,
				}
			}
			return resp.data
		} catch (e) {
			console.log(e)
			// router.push('/500')
			return e.response
		}
	}
}

export async function getBid(bid_id) {
	let resp
	try {
		resp = await get('distributor/bid/' + bid_id)
		return resp.data
	} catch (e) {
		console.log(e)
		// router.push('/500')
		return e.response
	}
}

export async function cancelBid(bid_id) {
	let resp
	try {
		resp = await destory('distributor/bid/' + bid_id)
		return resp.data
	} catch (e) {
		console.log(e)
		// router.push('/500')
		return e.response
	}
}
